type Address = {
  street?: string | null
  streetNumber?: string | null
  formattedStreet?: string | null
  unit?: string | null
  city?: string | null
  state?: string | null
  zipcode?: string | null
}

export function formatAddressString(address?: Address) {
  let displayUnitString = ''
  if (address?.unit) {
    // unit is stored by geocode as # 1 -> we want to display it as #1
    displayUnitString = ` ${address.unit.replace(' ', '')}`
  }
  if (address && address.street) {
    return `${address.street}${displayUnitString}`.trim()
  }
  if (address && address.formattedStreet) {
    return `${address.streetNumber} ${address.formattedStreet}${displayUnitString}`
  }
  return ''
}

export function formatCityStateZip(address?: Address) {
  if (address) {
    return `${address.city}, ${address.state} ${address.zipcode}`
  }
  return ''
}

export const getAddressStreet = (address: Address) => {
  const { streetNumber, formattedStreet } = address
  return `${streetNumber} ${formattedStreet}`
}
